import React from "react";

export default class InputSelectColor extends React.Component {
  render() {
    const {name, onChange, value} = this.props;

    return (
      <select className="w-full" name={name} onChange={onChange} value={value}>
        <option value="charcoal">Charcoal Black</option>
        <option value="cherryRed">Cherry Red</option>
        <option value="chocolateBrown">Chocolate Brown</option>
        <option value="deepBlue">Deep Blue</option>
        <option value="goldenYellow">Golden Yellow</option>
        <option value="hotPink">Hot Pink</option>
        <option value="mandarinOrange">Mandarin Orange</option>
        <option value="plumPurple">Plum Purple</option>
        <option value="snowWhite">Snow White</option>
      </select>
    );
  }
}
