
const TreeColors = {
  charcoal: '#111111',
  cherryRed: '#F70031',
  chocolateBrown: '#784821',
  deepBlue: '#2963AD',
  goldenYellow: '#F8D820',
  hotPink: '#EA0069',
  mandarinOrange: '#FFA814',
  plumPurple: '#6C35B7',
  snowWhite: '#FFFFFF',
};

const TreeColorFills = {
  redGreen: {
    treeFillId: 'TreeGreen',
  },
  purpleWhite: {
    treeFillId: 'TreeWhite',
  },
  pinkForestGreen: {
    treeFillId: 'TreeForestGreen',
  },
  whiteBlue: {
    treeFillId: 'TreeBlue',
  },
  blueOrange: {
    treeFillId: 'TreeOrange',
  },
  orangePurple: {
    treeFillId: 'TreePurple',
  },
  brownRed: {
    treeFillId: 'TreeBrown',
  },
  whiteBlack: {
    treeFillId: 'TreeBlack',
  },
  redWhite: {
    treeFillId: 'TreeZinc',
  },
};

function GetTreeFillId(colorSet) {
  return TreeColorFills[colorSet].treeFillId;
}

function GetOrnamentColor(colorSet) {
  const ornamentColors = {
    redGreen: {
      ornaments: TreeColors.cherryRed
    },
    purpleWhite: {
      ornaments: TreeColors.plumPurple
    },
    pinkForestGreen: {
      ornaments: TreeColors.hotPink
    },
    whiteBlue: {
      ornaments: TreeColors.snowWhite
    },
    blueOrange: {
      ornaments: TreeColors.deepBlue
    },
    orangePurple: {
      ornaments: TreeColors.mandarinOrange
    },
    brownRed: {
      ornaments: TreeColors.chocolateBrown
    },
    whiteBlack: {
      ornaments: TreeColors.snowWhite
    },
    redWhite: {
      ornaments: TreeColors.cherryRed
    },
  };

  return ornamentColors[colorSet].ornaments;
}

export {GetOrnamentColor, GetTreeFillId, TreeColors, TreeColorFills};
