import React from "react";

export default class InputSelectColorPairs extends React.Component {
  render() {
    const {name, onChange, value} = this.props;

    return (
      <select className="w-full" name={name} onChange={onChange} value={value}>
        <option value="redGreen">Cherry Red on Spruce Green</option>
        <option value="purpleWhite">Plum Purple on Snow White</option>
        <option value="pinkForestGreen">Hot Pink on Forest Green</option>
        <option value="whiteBlue">Snow White on Deep Blue</option>
        <option value="blueOrange">Deep Blue on Mandarin Orange</option>
        <option value="orangePurple">Mandarin Orange on Plum Purple</option>
        <option value="brownRed">Chocolate Brown on Cherry Red</option>
        <option value="whiteBlack">Snow White on Charcoal Black</option>
        <option value="redWhite">Cherry Red on Zinc White</option>
      </select>
    );
  }
}
