import React from "react";

export default class FeedbackPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      svgCode: 'Note: SVG code will appear here'
    };

    this.onRandomize = this.onRandomize.bind(this);
    this.onCopySVG = this.onCopySVG.bind(this);
  }

  onRandomize() {
    this.props.onRandomize();
  }

  onCopySVG() {
    this.props.onCopySVG();
  }

  render() {
    return (
      <section className="sm:w-full lg:w-2/12 ">
        <h1>Feedback</h1>

        <div className="bg-gray-med ">
          <h2>Random</h2>

          <div className="pl-3 pt-6 pr-3 pb-3">
            <div className="input-group-class">
              <h3 className="mb-5">Tap the button, switch it up, and find what you get. Always
                something different to see!</h3>
              <div className="text-center">
                <button onClick={this.onRandomize}>Random</button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-med rounded-b-3xl">
          <h2>Export</h2>

          <div className="pl-3 pt-6 pr-3 pb-3">
            <div className="input-group-class">
              <h3 className="mb-5">Tap the button and full SVG HTML will appear. Copy-and-paste it
                into your web page to easily share your Christmas tree!</h3>
              <div className="text-center">
                <button onClick={this.onCopySVG}>Copy SVG</button>
              </div>
            </div>

            <div className="input-group-class">
              <textarea className="w-full" rows="13" value={this.props.tree.svgCode} readOnly/>
            </div>

          </div>
        </div>

      </section>
    );
  }

}
