import React from "react";
import SettingsPanel from "./settings-panel";
import TreeVizPanel from "./tree-viz-panel";
import FeedbackPanel from "./feedback-panel";
import {TreeColors, TreeColorFills} from "./colors";
import LogoHeader from "./logo-header";
import Footer from "./footer";

const defaultSvgPrompt = 'Note: SVG code will appear here';

export default class CreateTreePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tree: {
        bodyBranchCount: TreeVizPanel.defaultTreeBranches,
        bodyOrnamentColors: 'redGreen',
        bodyWidth: TreeVizPanel.defaultTreeWidth,
        areBodyBranchShadowsShown: true,
        isBodyShadowShown: true,

        isStarTopperShown: true,
        starTopperColor: 'goldenYellow',

        isGarlandShown: true,
        isGarlandCurvy: true,
        garlandColor: 'goldenYellow',
        garlandWidth: TreeVizPanel.defaultGarlandWidth,

        svgCode: defaultSvgPrompt,
      }
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onRandomize = this.onRandomize.bind(this);
    this.onCopySVG = this.onCopySVG.bind(this);
  }

  onInputChange(event) {
    const tree = this.state.tree;
    tree[event.name] = event.value;
    tree.svgCode = defaultSvgPrompt;
    this.setState({
      tree
    });
  }

  calcRandomBoolean() {
    return !!Math.floor(Math.random() * 2);
  }

  calcRandomColor() {
    const colorKeys = Object.keys(TreeColors);
    const colorIndex = Math.floor(Math.random() * colorKeys.length);

    return colorKeys[colorIndex];
  }

  calcRandomColorSet() {
    const colorKeys = Object.keys(TreeColorFills);
    const colorIndex = Math.floor(Math.random() * colorKeys.length);

    return colorKeys[colorIndex];
  }

  onCopySVG() {
    const tree = this.state.tree;
    tree.svgCode = document.getElementById('tree-visual').innerHTML;
    this.setState({
      tree
    });
  }

  onRandomize() {
    const tree = this.state.tree;

    tree.bodyOrnamentColors = this.calcRandomColorSet();
    tree.bodyBranchCount = Math.floor(Math.random() * TreeVizPanel.maxTreeBranches);
    if (tree.bodyBranchCount < 1) {
      tree.bodyBranchCount = 1;
    }
    tree.areBodyBranchShadowsShown = this.calcRandomBoolean();
    tree.bodyWidth = Math.floor(Math.random() * TreeVizPanel.maxTreeWidth);
    if (tree.bodyWidth < TreeVizPanel.minTreeWidth) {
      tree.bodyWidth = TreeVizPanel.minTreeWidth
    }
    tree.isBodyShadowShown = true;

    tree.isStarTopperShown = this.calcRandomBoolean();
    tree.starTopperColor = this.calcRandomColor();

    tree.isGarlandShown = this.calcRandomBoolean();
    tree.isGarlandCurvy = this.calcRandomBoolean();
    tree.garlandColor = this.calcRandomColor();
    tree.garlandWidth = Math.floor(Math.random() * TreeVizPanel.maxGarlandWidth) + 1;

    tree.svgCode = defaultSvgPrompt;

    this.setState({
      tree
    });
  }

  render() {
    return (
      <div className="mx-auto max-w-screen-2xl p-6" id="main-content">
        <LogoHeader/>
        <div className="flex gap-4">
          <SettingsPanel tree={this.state.tree} onChange={this.onInputChange}/>
          <TreeVizPanel tree={this.state.tree}/>
          <FeedbackPanel tree={this.state.tree} onRandomize={this.onRandomize}
                         onCopySVG={this.onCopySVG}/>
        </div>
        <Footer/>
      </div>
    );
  }
}
