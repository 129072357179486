import React from "react";
import {GetOrnamentColor, GetTreeFillId, TreeColors} from "./colors";
import TreeColorDefs from "./tree-color-defs";

const defaultTreeBranches = 4;
const defaultTreeHeight = 100.0;
const defaultTreeWidth = 40.0;
const defaultShadowWidth = 35.0;
const defaultOrnamentSize = 3.5;
const defaultGarlandWidth = 3;

const maxTreeBranches = 7;
const minTreeWidth = 20.0;
const maxTreeWidth = 60.0;
const treeMidX = 50.0;
const maxGarlandWidth = 7;

export default class TreeVizPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMouseDown: false,
      mouseDownTimeStart: 0,
    };

    this.onMouseLeaveTree = this.onMouseLeaveTree.bind(this);
    this.onMouseDownTree = this.onMouseDownTree.bind(this);
    this.onMouseUpTree = this.onMouseUpTree.bind(this);
  }

  static get defaultTreeWidth() {
    return defaultTreeWidth;
  }

  static get minTreeWidth() {
    return minTreeWidth;
  }

  static get maxTreeWidth() {
    return maxTreeWidth;
  }

  static get defaultTreeBranches() {
    return defaultTreeBranches;
  }

  static get maxTreeBranches() {
    return maxTreeBranches;
  }

  static get defaultGarlandWidth() {
    return defaultGarlandWidth;
  }

  static get maxGarlandWidth() {
    return maxGarlandWidth;
  }

  calcShadowWidth() {
    const mag = this.props.tree.bodyWidth / defaultTreeWidth;
    const shadowWidth = defaultShadowWidth * mag;

    return (shadowWidth);
  }

  calcBodyPoints() {
    const branchInsetX = this.props.tree.bodyWidth * 0.25;
    const deltaX = this.props.tree.bodyWidth / this.props.tree.bodyBranchCount;
    const deltaY = defaultTreeHeight / this.props.tree.bodyBranchCount;

    let bodyPoints = `${treeMidX},0 `;
    let leftSidePoints = [];
    let shadowPoints = [];

    for (let i = 1; i <= this.props.tree.bodyBranchCount; i++) {
      let x = (deltaX * i) + treeMidX;
      let y = deltaY * i;
      bodyPoints = bodyPoints + `${x},${y} `;

      let invMag = 1.0 - ((this.props.tree.bodyBranchCount - i) / this.props.tree.bodyBranchCount);
      let xInsetMag = branchInsetX * invMag;
      let xInset = x - xInsetMag;
      let insetPoint = `${xInset},${y} `;
      bodyPoints = bodyPoints + insetPoint;

      let xMirrorLeft = treeMidX - (deltaX * i);
      let leftSidePoint = `${xMirrorLeft},${y} `;
      leftSidePoints.unshift(leftSidePoint);

      let xMirrorLeftInset = xMirrorLeft + xInsetMag;
      let leftSidePointInset = `${xMirrorLeftInset},${y} `;
      leftSidePoints.unshift(leftSidePointInset);

      if (i < this.props.tree.bodyBranchCount) {
        shadowPoints.push({
          p1: {x: xInset, y: y},
          p2: {x: xMirrorLeftInset, y: y}
        });
      }
    }

    leftSidePoints.forEach((point) => {
      bodyPoints = bodyPoints + point;
    });

    bodyPoints = bodyPoints.slice(0, -1);

    return {
      bodyPoints,
      shadowPoints
    }
  }

  calcOrnamentPoints() {
    let ornamentPoints = [];
    const locations = [
      {x: 0.10, y: 0.25},
      {x: -0.20, y: 0.40},
      {x: 0.30, y: 0.55},
      {x: -0.40, y: 0.70},
      {x: 0.60, y: 0.90},
    ];

    locations.forEach((point) => {
      let x = treeMidX + (point.x * this.props.tree.bodyWidth);
      let y = defaultTreeHeight * point.y;

      ornamentPoints.push({x, y});
    });

    return ornamentPoints;
  }

  calcGarlandStraight() {
    const garlandColor = TreeColors[this.props.tree.garlandColor];
    const ornamentPoints = this.calcOrnamentPoints();
    let pointList = '';

    ornamentPoints.forEach((point) => {
      let pointEntry = `${point.x},${point.y} `;
      pointList = pointList + pointEntry;
    });

    return <polyline id="garland" points={pointList} fill="transparent"
                     strokeWidth={this.props.tree.garlandWidth} stroke={garlandColor}/>;
  }

  calcGarlandCurvy() {
    const garlandColor = TreeColors[this.props.tree.garlandColor];
    const ornamentPoints = this.calcOrnamentPoints();
    let pointList = `M ${ornamentPoints[0].x} ${ornamentPoints[0].y} `;

    ornamentPoints.forEach((point, index, allPoints) => {
      if (index !== 0) {
        let mag = (index + 1) / allPoints.length;

        let xPrev = allPoints[index - 1].x;
        let yPrev = allPoints[index - 1].y;
        let dx = point.x - xPrev;
        let dy = point.y - yPrev;
        let xDir = Math.sign(dx);
        let yDir = Math.sign(dy);

        let cx1 = xPrev + (5 * mag * xDir);
        let cy1 = yPrev + (20 * mag);
        let cx2 = point.x + (10 * mag * xDir * -1);
        let cy2 = point.y + (5 * mag * yDir);

        let pointEntry = `C ${cx1} ${cy1}, ${cx2} ${cy2}, ${point.x} ${point.y} `;

        pointList = pointList + pointEntry;
      }
    });

    return <path id="garland" d={pointList} fill="transparent"
                 strokeWidth={this.props.tree.garlandWidth} stroke={garlandColor}/>;
  }

  onMouseDownTree() {
    // console.log('TODO: on mouse DOWN tree');

    this.setState({
      isMouseDown: true,
      mouseDownTimeStart: Date.now(),
    });
  }

  onMouseUpTree() {
    const mouseDownDuration = Date.now() - this.state.mouseDownTimeStart;

    if (this.state.isMouseDown) {
      // console.log('TODO: on mouse UP tree', mouseDownDuration);
    } else {
      // console.log('TODO: on mouse UP tree but left');
    }

    this.setState({
      isMouseDown: false,
    });
  }

  onMouseLeaveTree() {
    // console.log('TODO: on mouse LEAVE tree');

    this.setState({
      isMouseDown: false,
    });
  }

  render() {
    let starBlock;
    if (this.props.tree.isStarTopperShown) {
      let starColor = TreeColors[this.props.tree.starTopperColor];
      starBlock = <polygon id="star"
                           points="1.5,0 2,1 3,1 2.25,1.75 2.75,3 1.5,2.25, 0.25,3 0.75,1.75 0,1 1,1"
                           fill={starColor}
                           transform="scale(4,4) translate(11, -2.25)"/>;
    }

    let shadowBlock;
    if (this.props.tree.isBodyShadowShown) {
      const shadowWidth = this.calcShadowWidth();
      shadowBlock = <ellipse id="shadow" cx="50" cy="110" rx={shadowWidth} ry="5" fill="#d9d0e0"/>;
    }

    const {bodyPoints, shadowPoints} = this.calcBodyPoints();
    let bodyColor = `url(#${GetTreeFillId(this.props.tree.bodyOrnamentColors)})`;
    let treeBodyBlock = <polygon id="tree" points={bodyPoints} fill={bodyColor}/>;

    let branchShadowBlock;
    if (this.props.tree.areBodyBranchShadowsShown) {
      branchShadowBlock = shadowPoints.map((point, index) => (
        <line id={`shadow-line-${index}`} x1={point.p1.x} y1={point.p1.y}
              x2={point.p2.x} y2={point.p2.y} strokeWidth="1" stroke="#332244"
              strokeOpacity="0.20" key={index}/>
      ));

    }
    const ornamentPoints = this.calcOrnamentPoints();
    const ornamentColor = GetOrnamentColor(this.props.tree.bodyOrnamentColors);

    let ornamentsBlock = ornamentPoints.map((ornament, index) => (
      <circle id={`ornament-${index}`} cx={ornament.x} cy={ornament.y} r={defaultOrnamentSize}
              fill={ornamentColor} key={index}/>
    ));
    let garlandBlock;
    if (this.props.tree.isGarlandShown) {
      if (this.props.tree.isGarlandCurvy) {
        garlandBlock = this.calcGarlandCurvy();
      } else {
        garlandBlock = this.calcGarlandStraight();
      }
    }

    return (
      <section className="sm:w-full lg:w-7/12 ">
        <h1 className="text-center">Tree</h1>

        <div id="tree-visual"
             className="w-100 lg:w-[650px] lg:h-[650px] m-auto rounded-3xl border-2 border-solid border-gray-light bg-gradient-to-b from-sky-300 to-white">

          <svg id="tree-svg-container" width="100%" height="100%" viewBox="0 -10 100 130"
               xmlns="http://www.w3.org/2000/svg" onMouseLeave={this.onMouseLeaveTree}
               onMouseDown={this.onMouseDownTree} onMouseUp={this.onMouseUpTree}>

            {shadowBlock}
            <g id="trunk-group">
              <polygon id="trunk-top" points="45,100 55,100 55,104 45,104" fill="#612F18"/>
              <polygon id="trunk-bottom" points="45,103 55,103 55,110 45,110" fill="#804D2A"/>
            </g>
            {treeBodyBlock}
            <g id="branch-shadow-group">
              {branchShadowBlock}
            </g>
            {garlandBlock}
            {starBlock}

            <g id="ornament-group">
              {ornamentsBlock}
            </g>

            <TreeColorDefs/>
          </svg>

        </div>
      </section>
    );
  }
}
