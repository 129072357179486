import React from "react";

export default class Footer extends React.Component {

  render() {
    const pClasses = 'pb-2 leading-tight';
    return (
      <footer>
        <p className={pClasses}>Welcome to <strong>"Trees with Ken"</strong>. Explore, play, and
          have fun while
          you're here. </p>
        <p className={pClasses}>Take advantage of the <strong><a
          href="https://developer.mozilla.org/en-US/docs/Web/SVG">SVG code</a></strong> generation
          if you're a web developer.
          Drop the code into your site if you want to feature your tree as a nice detail.
          It's just as easy to take a screen capture of your tree and use it as a photo in
          whatever way you like.</p>
        <p className={pClasses}>
          Message me on <a href="https://twitter.com/KenTabor">Twitter</a> or <a
          href="https://www.linkedin.com/in/kentabor/">LinkedIn</a>.
          Tell me about your Christmas tree!
        </p>
        <p>
          For the software developers - <strong><a
          href="https://www.codeandchats.com/2022/11/17/trees-with-ken-how-it-works.html">read an
          article I wrote on how it works.</a></strong>
        </p>
      </footer>
    );
  }
}
