import React from "react";

export default class LogoHeader extends React.Component {

  render() {
    return (
      <header
        className="pb-2 mb-8 flex flex-row items-center border-b-2 border-solid border-orange-bright">
        <p className="text-2xl text-gray-50 font-medium uppercase">Christmas Tree Generator 2.0</p>

        <span className="ml-auto">
          <a href="https://www.codeandchats.com/">
            <img className="h-10" src="CodeAndChatsLogoLetterMarkWhite.svg"
                 alt="code and chats logo"/>
          </a>
        </span>
      </header>
    );
  }
}
