import React from "react";

export default class InputSliderGroup extends React.Component {
  render() {
    const {label, min, max, name, step, value, onChange} = this.props;

    return (
      <div className="input-group-class">
        <h3>{label}</h3>
        <span className="setting-value">{value}</span>
        <input type="range" min={min} max={max} step={step} value={value}
               name={name} onChange={onChange}/>
      </div>
    );
  }
}
