import React from "react";
import InputSelectColor from "./input-select-color";

export default class InputSelectColorGroup extends React.Component {
  render() {
    const {label, name, onChange, value} = this.props;

    return (
      <div className="input-group-class">
        <h3>{label}</h3>
        <InputSelectColor name={name} onChange={onChange} value={value}/>
      </div>
    );
  }
}
