import React from "react";
import InputSelectColorPairs from "./input-select-color-pairs";

export default class InputSelectColorPairsGroup extends React.Component {

  render() {
    const {label, name, onChange, value} = this.props;

    return (
      <div className="input-group-class">
        <h3>{label}</h3>
        <InputSelectColorPairs name={name} onChange={onChange} value={value}/>
      </div>
    );
  }

}
