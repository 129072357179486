import React from "react";
import InputCheckboxGroup from "./input-checkbox-group";
import InputSliderGroup from "./input-slider-group";
import InputSelectColorGroup from "./input-select-color-group";
import InputSelectColorPairsGroup from "./input-select-color-pairs-group";

export default class SettingsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.props.onChange({
      name,
      value
    });
  }

  render() {
    const isBodyBranchShadowsShownChecked = this.props.tree.areBodyBranchShadowsShown === true;
    const isBodyShadowShownChecked = this.props.tree.isBodyShadowShown === true;
    const isStarTopperShownChecked = this.props.tree.isStarTopperShown === true;
    const isGarlandShownChecked = this.props.tree.isGarlandShown === true;
    const isGarlandCurvyChecked = this.props.tree.isGarlandCurvy === true;

    return (
      <section className="sm:w-full lg:w-3/12 ">
        <h1>Settings</h1>

        <div className="bg-gray-med">
          <h2>Tree Body</h2>

          <div className="pl-3 pt-6 pr-3 pb-3">
            <InputSliderGroup label="Number of Branches" min={1} max={6} step={1}
                              value={this.props.tree.bodyBranchCount} name="bodyBranchCount"
                              onChange={this.onInputChange}/>

            <InputSelectColorPairsGroup label="Body & Ornaments Color"
                                        name="bodyOrnamentColors" onChange={this.onInputChange}
                                        value={this.props.tree.bodyOrnamentColors}/>

            <InputSliderGroup label="Body Width" min={20} max={60} step={5}
                              value={this.props.tree.bodyWidth} name="bodyWidth"
                              onChange={this.onInputChange}/>

            <InputCheckboxGroup isChecked={isBodyBranchShadowsShownChecked} label="Branch Shadows"
                                name="areBodyBranchShadowsShown" onChange={this.onInputChange}/>

            <InputCheckboxGroup isChecked={isBodyShadowShownChecked} label="Body Shadow"
                                name="isBodyShadowShown" onChange={this.onInputChange}/>
          </div>
        </div>

        <div className="bg-gray-med">
          <h2>Star Topper</h2>

          <div className="pl-3 pt-6 pr-3 pb-3">
            <InputCheckboxGroup isChecked={isStarTopperShownChecked} label="Star Topper"
                                name="isStarTopperShown" onChange={this.onInputChange}/>

            <InputSelectColorGroup label="Star Color" name="starTopperColor"
                                   onChange={this.onInputChange}
                                   value={this.props.tree.starTopperColor}/>
          </div>
        </div>

        <div className="bg-gray-med rounded-b-3xl">
          <h2>Garland</h2>

          <div className="pl-3 pt-6 pr-3 pb-3">
            <InputCheckboxGroup isChecked={isGarlandShownChecked} label="Garland"
                                name="isGarlandShown" onChange={this.onInputChange}/>

            <InputCheckboxGroup isChecked={isGarlandCurvyChecked} label="Garland Curvy"
                                name="isGarlandCurvy" onChange={this.onInputChange}/>

            <InputSelectColorGroup label="Garland Color" name="garlandColor"
                                   onChange={this.onInputChange}
                                   value={this.props.tree.garlandColor}/>

            <InputSliderGroup label="Garland Width" min={1} max={7} step={1}
                              value={this.props.tree.garlandWidth} name="garlandWidth"
                              onChange={this.onInputChange}/>
          </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" className="hidden">
          <symbol id="checkmark" viewBox="0 0 320 512">
            <path stroke="inherit"
                  d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/>
          </symbol>
        </svg>

      </section>
    );
  }

}
