import React from "react";

export default class TreeColorDefs extends React.Component {
  render() {
    return(
      <defs>
        <linearGradient id="TrunkBrown" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#511F08"/>
          <stop offset="15%" stopColor="#804D2A"/>
          <stop offset="75%" stopColor="#612F18"/>
        </linearGradient>

        <linearGradient id="TreeGreen" gradientTransform="rotate(90)">
          <stop offset="25%" stopColor="#95E176"/>
          <stop offset="75%" stopColor="#6CB136"/>
        </linearGradient>

        <linearGradient id="TreeOrange" gradientTransform="rotate(90)">
          <stop offset="25%" stopColor="#F7BA29"/>
          <stop offset="75%" stopColor="#DF9004"/>
        </linearGradient>

        <linearGradient id="TreeBlue" gradientTransform="rotate(90)">
          <stop offset="25%" stopColor="#388FF3"/>
          <stop offset="75%" stopColor="#286FD3"/>
        </linearGradient>

        <linearGradient id="TreeWhite" gradientTransform="rotate(90)">
          <stop offset="25%" stopColor="#E4E6E6"/>
          <stop offset="75%" stopColor="#C4C6C6"/>
        </linearGradient>

        <linearGradient id="TreeForestGreen" gradientTransform="rotate(90)">
          <stop offset="25%" stopColor="#326F43"/>
          <stop offset="75%" stopColor="#053E13"/>
        </linearGradient>

        <linearGradient id="TreePurple" gradientTransform="rotate(90)">
          <stop offset="25%" stopColor="#845EB4"/>
          <stop offset="75%" stopColor="#6D4E96"/>
        </linearGradient>

        <linearGradient id="TreeBrown" gradientTransform="rotate(90)">
          <stop offset="25%" stopColor="#FF8897"/>
          <stop offset="75%" stopColor="#F8384C"/>
        </linearGradient>

        <linearGradient id="TreeBlack" gradientTransform="rotate(90)">
          <stop offset="25%" stopColor="#333344"/>
          <stop offset="75%" stopColor="#111111"/>
        </linearGradient>

        <linearGradient id="TreeZinc" gradientTransform="rotate(90)">
          <stop offset="25%" stopColor="#ffffff"/>
          <stop offset="75%" stopColor="#E4E6E6"/>
        </linearGradient>
      </defs>
    );
  }
}
